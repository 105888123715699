import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  distance?: string
  title?: string
}

export const Item = memo(function Item({ distance, title }: Props) {
  if (!distance || !title) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {title ? <Title>{title}</Title> : null}
      {distance ? <Distance>{distance}</Distance> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  margin-top: 3.125rem;
  padding-left: 1.625rem;
  position: relative;
  &:first-of-type {
    margin-top: 0;
  }
  &:before {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    border-radius: 50%;
    position: absolute;
    top: 0.4375rem;
    left: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Title = styled.div``

const Distance = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  &:before {
    content: '-';
    font-weight: 400;
    margin: 0 0.375rem;
  }
`
